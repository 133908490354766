import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import LoginLayout from '../pages/Login';
import DashboardLayout from '../pages/_layouts/dashboard';

function RouteWrapper({ component: Component, isPrivate, ...rest }, ref) {
  const signed =  JSON.parse(localStorage.getItem('login')) !== null;

  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  if (signed && !isPrivate) {
    return <Redirect to="/atendimento" />;
  }

  const Layout = signed ? DashboardLayout : LoginLayout;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} ref={ref} />
        </Layout>
      )}
    />
  );
}

export default forwardRef(RouteWrapper);

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
