import React from "react";
import { Switch } from "react-router-dom";

import Route from "./Route";

import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import Atendimento from "../pages/Atendimento";
import ListarAtendimentos from "../pages/ListarAtendimentos";
import InteragirAtendimento from "../pages/InteragirAtendimento";
import GestaoAtendimento from "../pages/GestaoAtendimento";
import GestaoAtendimentoApproved from "../pages/GestaoAtendimento/atendimento";
import AcompanharDemandasEquipe from "../pages/GestaoAtendimento/equie";
import AtendimentoAgente from "../pages/AtendimentoAgente";
import Relatorios from "../pages/Relatorios";
import RelatoriosAtendimentoAbertos from "../pages/Relatorios/AtendimentoAbertos";
import RelatoriosAtendimentoFechados from "../pages/Relatorios/AtendimentoFechados";
import OpenTicket from "../pages/OpenTicket";
import CadastrarUsuario from "../pages/CadastrarUsuario";
import FaltasCaixa from "../pages/FaltasCaixa";

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/atendimento" component={Atendimento} isPrivate />
      <Route
        path="/listaratendimentos"
        component={ListarAtendimentos}
        isPrivate
      />
      <Route
        path="/interagiratendimento/:id"
        component={InteragirAtendimento}
        isPrivate
      />
      <Route
        path="/gestaoatendimentos"
        component={GestaoAtendimento}
        isPrivate
      />
      <Route
        path="/gestaoatendimentos"
        component={GestaoAtendimento}
        isPrivate
      />
      <Route
        path="/gestaoatendimentosapproved/:id"
        component={GestaoAtendimentoApproved}
        isPrivate
      />
      <Route
        path="/acompanharequipe"
        component={AcompanharDemandasEquipe}
        isPrivate
      />
      <Route
        path="/agenteatendimentos/"
        component={AtendimentoAgente}
        isPrivate
      />
      <Route path="/relatorios/" component={Relatorios} isPrivate />
      <Route
        path="/RelatorioAtendimentoAbertos"
        component={RelatoriosAtendimentoAbertos}
        isPrivate
      />
      <Route
        path="/RelatorioAtendimentoFechados"
        component={RelatoriosAtendimentoFechados}
        isPrivate
      />
      <Route path="/openTicket" component={OpenTicket} isPrivate />
      <Route path="/registerUser" component={CadastrarUsuario} isPrivate />
      <Route path="/faltaCaixa" component={FaltasCaixa} isPrivate />
      <Route path="/" component={() => <h1>404</h1>} />
    </Switch>
  );
}
