import React, { useState, useEffect, useCallback } from "react";
import { uniqueId } from "lodash";
import filesize from "filesize";

import api from "../../services/api";

import { Container, Content } from "./styles";
import Upload from "../../component/Upload";
import FileList from "../../component/FileList";

const FileUpload = ({ atendimento_id, cpf, usuario_id, sendFile }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);

  // Função para carregar arquivos já enviados ao montar o componente
  useEffect(() => {
    const loadUploadedFiles = async () => {
      try {
        const response = await api.get(`atendimento/${atendimento_id}/files`);
        const files = response.data.map((file) => ({
          id: uniqueId(),
          name: file.name,
          readableSize: filesize(file.size),
          preview: file.url,
          uploaded: true,
          url: file.url,
          data: file.date,
          user: file.usuario.nome,
          id_api: file.id,
        }));
        setUploadedFiles(files);
      } catch (error) {
        console.error("Erro ao carregar arquivos", error);
      }
    };

    loadUploadedFiles();
  }, [atendimento_id]);

  // Função para processar o upload de cada arquivo
  const processUpload = useCallback(
    (uploadedFile) => {
      const data = new FormData();
      data.append("file", uploadedFile.file, uploadedFile.name);

      api
        .post(
          `atendimento/${atendimento_id}/files/${cpf}/user/${usuario_id}`,
          data,
          {
            onUploadProgress: (e) => {
              const progress = parseInt(Math.round((e.loaded * 100) / e.total));
              updateFile(uploadedFile.id, { progress });
            },
          }
        )
        .then((response) => {
          updateFile(uploadedFile.id, {
            uploaded: true,
            id: response.data.id,
            url: response.data.url,
            cpf,
            user_id: usuario_id,
          });
        })
        .catch(() => {
          updateFile(uploadedFile.id, { error: true });
        });
    },
    [atendimento_id, cpf, usuario_id]
  );

  // Função para lidar com o upload de novos arquivos
  const handleUpload = useCallback(
    (files) => {
      const dataLocalStorage = JSON.parse(localStorage.getItem("login"));
      const today = new Date();
      const formattedDate = `${String(today.getDate()).padStart(
        2,
        "0"
      )}/${String(today.getMonth() + 1).padStart(
        2,
        "0"
      )}/${today.getFullYear()}`;

      const newFiles = files.map((file) => ({
        file,
        id: uniqueId(),
        name: file.name,
        readableSize: filesize(file.size),
        preview: URL.createObjectURL(file),
        progress: 0,
        uploaded: false,
        error: false,
        url: null,
        cpf,
        user: dataLocalStorage.nome,
        data: formattedDate,
        user_id: usuario_id,
      }));

      setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);

      newFiles.forEach(processUpload);
    },
    [cpf, usuario_id, processUpload]
  );

  // Função para atualizar um arquivo no estado
  const updateFile = (id, data) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.map((file) => (file.id === id ? { ...file, ...data } : file))
    );
  };

  // Função para deletar um arquivo
  const handleDelete = useCallback(async (id, id_api) => {
    const idToDelete = id_api || id;

    try {
      await api.delete(`files/${idToDelete}`);
    } catch (error) {
      console.error("Erro ao deletar arquivo", error);
    }

    setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  }, []);

  // Cleanup das pré-visualizações quando o componente for desmontado
  useEffect(() => {
    return () => {
      uploadedFiles.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [uploadedFiles]);

  return (
    <Container>
      <Content>
        {sendFile && <Upload onUpload={handleUpload} />}
        {!!uploadedFiles.length && (
          <FileList
            files={uploadedFiles}
            onDelete={handleDelete}
            onDelFile={sendFile}
          />
        )}
      </Content>
    </Container>
  );
};

export default FileUpload;
