import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { Grid, TextField } from "@mui/material";

export default function Bonus({ id }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await api.get(`/bonus/${id}`);
      setData(response.data);
    }
    fetchData();
  }, [id]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          id="tipo_movimentacao"
          label="Tipo de Movimentação"
          fullWidth
          value={data[0]?.tipo || ""}
          disabled
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          id="competencia"
          label="Competência"
          fullWidth
          value={`${data[0]?.competencia} - ${data[0]?.plano}` || ""}
          disabled
        />
      </Grid>
    </Grid>
  );
}
