import React, { useEffect, useState } from "react";
import { Grid, TextField } from "@mui/material";
import api from "../../services/api";

export default function Diversos({ id, competencia }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await api.get(`diversos/${id}`);
      setData(response.data);
    }
    fetchData();
  }, [id]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <TextField
          id="tipo_movimentacao"
          label="Tipo de Movimentação"
          fullWidth
          value={data[0]?.tipo || ""}
          disabled
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          id="competencia"
          label="Competência"
          fullWidth
          value={competencia || ""}
          disabled
        />
      </Grid>

      <Grid item xs={8}>
        <TextField
          id="nome"
          label="Nome"
          fullWidth
          value={`${data[0]?.matricula} -  ${data[0]?.nome}` || ""}
          disabled
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          id="funcao"
          label="Função"
          fullWidth
          value={data[0]?.funcao || ""}
          disabled
        />
      </Grid>
    </Grid>
  );
}
