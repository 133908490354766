import { createStore } from 'redux';

const INITIAL_STATE = {
    //data: [],
    cod_protheus: null,
    nome: null,
    email: null,
    gestor: null,
};

function login( state = INITIAL_STATE, action ){

    switch (action.type) {
        case 'ADD_LOGIN':
            return { 
                ...state, 
                cod_protheus: action.cod_protheus, 
                nome: action.nome, 
                email: action.email,
                gestor: action.gestor,
            };
        case 'REMOVE_STATE':
                return { 
                    cod_protheus: null,
                    nome: null,
                    email: null,
                    gestor: null,
                };
        default:
            return state;
    }
}

const store = createStore(login);

export default store;