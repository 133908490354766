import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import api from "../../services/api";
import HorizontalLineWithText from "../HorizontalLineText";

export default function InteragirAtendimento({ id }) {
  const [mudancaFuncao, setMudancaFuncao] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await api.get(`mudancaFuncao/${id}`);
      setMudancaFuncao(response.data);
    }
    fetchData();
  }, [id]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          autoComplete="Nome Funcaionário"
          name="Nome Funcaionário"
          variant="outlined"
          required
          fullWidth
          id="filail"
          color="secondary"
          label="Nome Funcaionário"
          value={mudancaFuncao[0]?.nome || ""}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          autoComplete="Matricula"
          name="Matricula"
          variant="outlined"
          required
          fullWidth
          id="filail"
          color="secondary"
          label="Matricula"
          value={mudancaFuncao[0]?.matricula || ""}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          autoComplete="Tipo de alteração"
          name="Tipo de alteração"
          variant="outlined"
          required
          fullWidth
          id="filail"
          color="secondary"
          label="Tipo de alteração"
          value={
            mudancaFuncao[0]?.tipo_mudanca_func?.nome_tipo_mudanca_funcao || ""
          }
          disabled
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Função"
          id="funcao"
          name="funcao"
          fullWidth
          variant="outlined"
          color="secondary"
          disabled
          value={mudancaFuncao[0]?.funcao_atual || ""}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Centro de Custo"
          id="centro_custo"
          name="centro_custo"
          fullWidth
          variant="outlined"
          color="secondary"
          disabled
          value={mudancaFuncao[0]?.centro_custo_atual || ""}
        />
      </Grid>

      <Grid item xs={12}>
        <HorizontalLineWithText text="Mudanças" />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Função"
          id="funcao"
          name="funcao"
          fullWidth
          variant="outlined"
          color="secondary"
          disabled
          value={mudancaFuncao[0]?.nova_funcao || ""}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Centro de Custo"
          id="centro_custo"
          name="centro_custo"
          fullWidth
          variant="outlined"
          color="secondary"
          disabled
          value={mudancaFuncao[0]?.novo_centro_custo || ""}
        />
      </Grid>
    </Grid>
  );
}
