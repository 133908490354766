import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { Grid, TextField } from "@mui/material";

export default function SancaoDisciplinar({ id }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await api.get(`sancaoDisciplinar/${id}`);
      setData(response.data);
    }
    fetchData();
  }, [id]);

  //   const date = Date(data[0]?.aplicacao);
  //   console.log("New Date", date);

  //   console.log("Date(): ", format(Date(date), "dd/MM/yyyy"));

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          label="Tipo de Movimentação"
          value={data[0]?.tipo || ""}
          disabled
          variant="outlined"
          color="secondary"
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Função"
          value={data[0]?.funcao || ""}
          disabled
          variant="outlined"
          color="secondary"
          fullWidth
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          label="Matrícula"
          value={data[0]?.matricula || ""}
          disabled
          variant="outlined"
          color="secondary"
          fullWidth
        />
      </Grid>

      <Grid item xs={8}>
        <TextField
          label="Funcionário"
          value={data[0]?.funcionario || ""}
          disabled
          variant="outlined"
          color="secondary"
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Aplicação"
          value={data[0]?.aplicacao.substring(0, 10) || ""}
          disabled
          variant="outlined"
          color="secondary"
          fullWidth
        />
      </Grid>
      {data[0]?.tipo.includes("2") && (
        <Grid item xs={6}>
          <TextField
            label="Retorno"
            value={data[0]?.retorno.substring(0, 10) || ""}
            disabled
            variant="outlined"
            color="secondary"
            fullWidth
          />
        </Grid>
      )}
    </Grid>
  );
}
