import React from "react";

const HorizontalLineWithText = ({ text }) => {
  return (
    <div style={styles.container}>
      <div style={styles.shortLine}></div>
      <span style={styles.text}>{text}</span>
      <div style={styles.line}></div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    width: "100%",
  },
  shortLine: {
    width: "10px", // Linha curta antes do texto
    height: "1px",
    backgroundColor: "#757575",
  },
  line: {
    flexGrow: 1,
    height: "1px",
    backgroundColor: "#757575",
  },
  text: {
    margin: "0 10px",
    color: "#757575", // Cor de subtítulo
    fontSize: "16px",
  },
};

export default HorizontalLineWithText;
