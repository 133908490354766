/* eslint-disable no-unused-vars */
import DateFnsUtils from "@date-io/date-fns";
import { Form } from "@unform/web";
import pt from "date-fns/locale/pt-BR";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import ButtonNovo from "../../component/ButtonRed";

import api from "../../services/api";
import history from "../../services/history";
import {
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import SelectReact from "react-select";
import { red } from "@material-ui/core/colors";
import HorizontalLineWithText from "../../component/HorizontalLineText";
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core";
import { format } from "date-fns";
import { Bounce, toast, ToastContainer } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

//const cookies = new Cookies();

export default function Atendimento(/*{history}*/) {
  const classes = useStyles();

  const [filial, setFilial] = useState("");

  const [admissao, setAdmissao] = useState("");
  const [demissao, setDemissao] = useState("");
  const [mudancaFuncao, setMudancaFuncao] = useState("");

  const [atendimento, setAtendimento] = useState("");

  const [descricao, setDescricao] = useState("");

  const [competencia, setCompetencia] = useState("");

  const [folhaPontoInicio, setFolhaPontoInicio] = useState(new Date());
  const [folhaPontoFim, setFolhaPontoFim] = useState(new Date());

  const [admissaoFuncao, setAdmissaoFuncao] = useState("");
  const [admissaoMatricula, setAdmissaoMatricula] = useState("");
  const [admissaoNovaFuncao, setAdmissaoNovaFuncao] = useState("");
  const [admissaoCentroCusto, setAdmissaoCentroCusto] = useState("");
  const [admissaoNovoFuncionario, setAdmissaoNovoFuncionario] = useState("");
  const [admissaoFilialOrigem, setAdmissaoFilialOrigem] = useState("");
  const [motivoAdmissao, setMotivoAdmissao] = useState("");
  const [funcionarioSubstituido, setFuncionarioSubstituido] = useState("");

  const [listaFuncoes, setListaFuncoes] = useState([]);

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);

  const [tiposAtendimento, setTiposAtendimento] = useState([]);
  const [tiposAdmissao, setTiposAdmissao] = useState([]);
  const [tiposDemissao, setTiposDemissao] = useState([]);
  const [tiposMudancaFuncao, setTiposMudancaFuncao] = useState([]);

  const [demissaoNomeFuncioario, setDemissaoNomeFuncionario] = useState("");
  const [demissaoMatricula, setDemissaoMatricula] = useState("");
  const [demissaoFuncao, setDemissaoFuncao] = useState("");
  const [demissaoDataDesligamento, setDemissaoDataDesligamento] = useState(
    new Date()
  );
  const [demissaoAvisoInicio, setDemissaoAvisoInicio] = useState(new Date());
  const [demissaoAvisoFim, setDemissaoAvisoFim] = useState(new Date());

  const [mudancaFuncaoNome, setMudancaFuncaoNome] = useState("");
  const [mudancaFuncaoMatricula, setMudancaFuncaoMatricula] = useState("");
  const [mudancaFuncaoFuncao, setMudancaFuncaoFuncao] = useState("");
  const [mudancaFuncaoCentroCusto, setMudancaFuncaoCentroCusto] = useState("");
  const [mudancaFuncaoNovaFuncao, setMudancaFuncaoNovaFuncao] = useState("");
  const [mudancaFuncaoNovoCC, setMudancaFuncaoNovoCC] = useState("");

  const [sancaoTipo, setSancaoTipo] = useState("");
  const [sancaoMatricula, setSancaoMatricula] = useState("");
  const [sancaoFuncao, setSancaoFuncao] = useState("");
  const [sancaoFuncionario, setSancaoFuncionario] = useState("");
  const [sancaoAplicacao, setSancaoAplicacao] = useState(new Date());
  const [sancaoRetorno, setSancaoRetorno] = useState(new Date());

  const [atestadosMatricula, setAtestadosMatricula] = useState("");
  const [atestadosNome, setAtestadosNome] = useState("");
  const [atestadosFuncao, setAtestadosFuncao] = useState("");
  const [tipoAtestados, setTipoAtestados] = useState("");
  const [atestadosAplicacao, setAtestadosAplicacao] = useState(new Date());
  const [atestadosRetorno, setAtestadosRetorno] = useState(new Date());
  const [atestadosOutros, setAtestadosOutros] = useState("");

  const [tipoPremiacao, setTipoPremiacao] = useState("");

  const [tipoBonus, setTipoBonus] = useState("");
  const [bonusPlano, setBonusPlano] = useState("");

  const [tipoBeneficio, setTipoBeneficio] = useState("");

  const [tipoDiversos, setTipoDiversos] = useState("");
  const [diversosMatricula, setDiversosMatricula] = useState("");
  const [diversosNome, setDiversosNome] = useState("");
  const [diversosFuncao, setDiversosFuncao] = useState("");

  const [listFilial, setListFilial] = useState([]);

  let admissaoArray = tiposAtendimento.find(
    (element) => element.nome_atendimento === "Admissão"
  );
  let demissaoArray = tiposAtendimento.find(
    (element) => element.nome_atendimento === "Demissão"
  );
  let mudancaFuncaoArray = tiposAtendimento.find(
    (element) => element.nome_atendimento === "Mudança de função"
  );

  let AumentoQuadroTemp = tiposAdmissao.find(
    (element) => element.nome_tipo_admissao === "Aumento de quadro temporário"
  );
  let AumentoQuadroEfet = tiposAdmissao.find(
    (element) => element.nome_tipo_admissao === "Aumento de quadro efetivo"
  );

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const response = await api.get("tiposatendimento");

      setTiposAtendimento(response.data);
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchDataAdmissao() {
      const response = await api.get("tiposadmissao");

      setTiposAdmissao(response.data);
    }

    fetchDataAdmissao();
  }, []);

  useEffect(() => {
    async function fetchDataDemissao() {
      const response = await api.get("tiposDemissao");

      setTiposDemissao(response.data);
    }

    fetchDataDemissao();
  }, []);

  useEffect(() => {
    async function fetchDataMudancaFuncao() {
      const response = await api.get("tiposMudancaFuncao");

      setTiposMudancaFuncao(response.data);
    }

    fetchDataMudancaFuncao();
  }, []);

  useEffect(() => {
    async function filialList() {
      const response = await api.get(`/filiais`);

      setListFilial(response.data);
    }

    filialList();
  }, []);

  useEffect(() => {
    async function ListaFuncoes() {
      const { data } = await api.get(`/consulta/funcoes`);

      setListaFuncoes(data);
    }

    ListaFuncoes();
  }, []);

  const handleChangeFilial = (event) => {
    setFilial(event.target.value);
  };

  const handleChangeAdmissao = (event) => {
    setFuncionarioSubstituido("");
    setMotivoAdmissao("");
    setMudancaFuncao("");
    setDemissao("");
    setAdmissao(event.target.value);
  };

  const handleChangeDemissao = (event) => {
    setDemissaoNomeFuncionario("");
    setDemissaoMatricula("");
    setMudancaFuncao("");
    setAdmissao("");
    //setDemissaoDataDesligamento(new Date());
    setDemissao(event.target.value);
  };

  const handleChangeMucancaFuncao = (event) => {
    setMudancaFuncaoNome("");
    setMudancaFuncaoMatricula("");
    setAdmissao("");
    setDemissao("");
    setMudancaFuncao(event.target.value);
  };

  const handleChangeAtendimento = (event) => {
    if (admissaoArray.id === atendimento) {
      setDemissao("");
      setDemissaoFuncao("");
      setDemissaoMatricula("");
      setDemissaoNomeFuncionario("");

      setMudancaFuncaoNome("");
      setMudancaFuncao("");
      setMudancaFuncaoMatricula("");

      setSancaoFuncao("");
      setSancaoFuncionario("");
      setSancaoMatricula("");
      setSancaoAplicacao("");
      setSancaoRetorno("");
      setSancaoTipo("");

      setCompetencia("");
    }

    if (demissaoArray.id === atendimento) {
      setMudancaFuncaoNome("");
      setMudancaFuncao("");
      setMudancaFuncaoMatricula("");

      setAdmissao("");
      setAdmissaoCentroCusto("");
      setAdmissaoFilialOrigem("");
      setAdmissaoFuncao("");
      setAdmissaoMatricula("");
      setAdmissaoNovaFuncao("");
      setAdmissaoNovoFuncionario("");
      setMotivoAdmissao("");

      setSancaoFuncao("");
      setSancaoFuncionario("");
      setSancaoMatricula("");
      setSancaoAplicacao("");
      setSancaoRetorno("");
      setSancaoTipo("");

      setCompetencia("");
    }

    if (mudancaFuncaoArray.id === atendimento) {
      setAdmissao("");
      setAdmissaoCentroCusto("");
      setAdmissaoFilialOrigem("");
      setAdmissaoFuncao("");
      setAdmissaoMatricula("");
      setAdmissaoNovaFuncao("");
      setAdmissaoNovoFuncionario("");
      setMotivoAdmissao("");

      setDemissao("");
      setDemissaoFuncao("");
      setDemissaoMatricula("");
      setDemissaoNomeFuncionario("");

      setSancaoFuncao("");
      setSancaoFuncionario("");
      setSancaoMatricula("");
      setSancaoAplicacao("");
      setSancaoRetorno("");
      setSancaoTipo("");

      setCompetencia("");
    }

    if (atendimento === 4) {
      setAdmissao("");
      setAdmissaoCentroCusto("");
      setAdmissaoFilialOrigem("");
      setAdmissaoFuncao("");
      setAdmissaoMatricula("");
      setAdmissaoNovaFuncao("");
      setAdmissaoNovoFuncionario("");
      setMotivoAdmissao("");

      setDemissao("");
      setDemissaoFuncao("");
      setDemissaoMatricula("");
      setDemissaoNomeFuncionario("");

      setMudancaFuncaoNome("");
      setMudancaFuncao("");
      setMudancaFuncaoMatricula("");

      setCompetencia("");
    }

    setAtendimento(event.target.value);
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom",
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  function handleHistoryBack(e) {
    e.preventDefault();
    history.push("/dashboard");
  }

  async function handleHistoryAtendimento(e) {
    e.preventDefault();
    console.log("Iniciar");

    if (filial === "") {
      Toast.fire({
        icon: "error",
        title: "O campo filial é obrigatório.",
      });
    } else if (atendimento === "") {
      Toast.fire({
        icon: "error",
        title: "O campo tipo de atendimento é obrigatório.",
      });
    } else if (descricao === "") {
      Toast.fire({
        icon: "error",
        title: "O campo descrição é obrigatório.",
      });
    } else if (
      mudancaFuncao !== "" &&
      (mudancaFuncaoNome === "" || mudancaFuncaoMatricula === "")
    ) {
      Toast.fire({
        icon: "error",
        title:
          mudancaFuncaoNome === ""
            ? "O campo Nome Funcaionário é obrigatório."
            : "O campo Matrícula é obrigatório.",
      });
    } else if (descricao.length > 255) {
      Toast.fire({
        icon: "error",
        title: "O campo descrição não pode ser maior que 255 caracteres",
      });
    } else {
      const dataLocalStorage = JSON.parse(localStorage.getItem("login"));

      let competenciaFormat;

      if (atendimento === 5) {
        const formatInicio = format(folhaPontoInicio, "dd/MM/yyyy");
        const formatFim = format(folhaPontoFim, "dd/MM/yyyy");

        competenciaFormat = `${formatInicio} - ${formatFim}`;
      }
      if (atendimento === 16) {
        const dateFormat = format(competencia, "MM/yyyy");
        competenciaFormat = `${tipoBeneficio} - ${dateFormat}`;
      } else {
        competenciaFormat = competencia && format(competencia, "MM/yyyy");
      }

      const response = await api.post(
        `tiposatendimento/${atendimento}/atendimento`,
        {
          // PRINCIPAIS

          filial: filial,
          descricao: descricao,
          status: "PENDENTE",
          user_solicitante: dataLocalStorage.id,
          user_responsavel: null,
          data: Date.now(),
          sla: null,
          competencia: competenciaFormat || null,

          // ADMISSÃO

          admissao_tipo: admissao,
          admissao_func_subs: funcionarioSubstituido,
          admissao_motivo: motivoAdmissao,
          admissao_cargo: admissaoFuncao,
          admissao_matricula: admissaoMatricula,
          admissao_nova_funcao: admissaoNovaFuncao?.value,
          admissao_centro_custo: admissaoCentroCusto,
          admissao_novo_funcionario: admissaoNovoFuncionario,
          admissao_filial_origem: admissaoFilialOrigem,

          // DEMISSÃO

          demissao_tipo: demissao,
          demissao_nome_func: demissaoNomeFuncioario,
          demissao_matricula: demissaoMatricula,
          demissao_data: demissaoDataDesligamento,
          demissao_funcao: demissaoFuncao,
          demissao_aviso_inicio: demissaoAvisoInicio,
          demissao_aviso_fim: demissaoAvisoFim,

          // MUDANÇA DE FUNÇÃO

          mudancaFuncao_tipo: mudancaFuncao,
          mudancaFuncao_nome_func: mudancaFuncaoNome,
          mucancaFuncao_matricula: mudancaFuncaoMatricula,
          mudanca_funcao_funcao_atual: mudancaFuncaoFuncao,
          mudanca_funcao_centro_custo_atual: mudancaFuncaoCentroCusto,
          mudanca_funcao_nova_funcao: mudancaFuncaoNovaFuncao?.value,
          mudanca_funcao_novo_centro_custo: mudancaFuncaoNovoCC,

          // SANCAO DISCIPLINAR

          sancao_disc_tipo: sancaoTipo,
          sancao_disc_matricula: sancaoMatricula,
          sancao_disc_funcionario: sancaoFuncionario,
          sancao_disc_funcao: sancaoFuncao,
          sancao_disc_aplicacao: sancaoAplicacao,
          sancao_disc_retorno: sancaoTipo.includes("2") ? sancaoRetorno : "",

          // ATESTADOS

          atestado_tipo:
            tipoAtestados === "Outros" ? atestadosOutros : tipoAtestados,
          atestado_matricula: atestadosMatricula,
          atestado_nome: atestadosNome,
          atestado_funcao: atestadosFuncao,
          atestado_aplicacao: atestadosAplicacao,
          atestado_retorno: atestadosRetorno,

          // PREMIACOES

          premiacao_tipo: tipoPremiacao,
          premiacao_competencia: competenciaFormat,

          // BONUS
          bonus_tipo: tipoBonus,
          bonus_plano: bonusPlano,
          bonus_competencia: competenciaFormat,

          // DIVERSOS
          diversos_tipo: tipoDiversos,
          diversos_matricula: diversosMatricula,
          diversos_nome: diversosNome,
          diversos_funcao: diversosFuncao,
        }
      );

      history.push(`interagiratendimento/${response.data.id}`);

      return response;
    }
  }

  async function ConsultaMatricula(e, mat) {
    e.preventDefault();
    try {
      if (filial === "") {
        toast.warn("Filial não informada!", {
          theme: "dark",
        });
      }

      const { data } = await api.post(`/consulta/matricula`, {
        filial,
        matricula: mat,
      });
      if (admissaoArray?.id === atendimento) {
        setAdmissaoFuncao(data[0].FUNCAO);
        setFuncionarioSubstituido(data[0].NOME);
      }
      if (demissaoArray?.id === atendimento) {
        setDemissaoNomeFuncionario(data[0]?.NOME || "");
        setDemissaoFuncao(data[0]?.FUNCAO || "");
      }
      if (mudancaFuncaoArray?.id === atendimento) {
        setMudancaFuncaoNome(data[0]?.NOME || "");
        setMudancaFuncaoFuncao(data[0]?.FUNCAO || "");
        // setMudancaFuncaoCentroCusto(
        //   `${data[0]?.CENTRO_CUSTO_ID}- ${data[0]?.CENTRO_CUSTO}` || ""
        // );
        setMudancaFuncaoCentroCusto(data[0]?.CENTRO_CUSTO || "");
      }

      // SANÇÃO DISCIPLINAR
      if (atendimento === 4) {
        setSancaoFuncionario(data[0]?.NOME || "");
        setSancaoFuncao(data[0]?.FUNCAO || "");
      }

      // ATESTADOS
      if (atendimento === 13) {
        setAtestadosNome(data[0]?.NOME || "");
        setAtestadosFuncao(data[0]?.FUNCAO || "");
      }
      // DIVERSOS
      if (atendimento === 17) {
        setDiversosNome(data[0]?.NOME || "");
        setDiversosFuncao(data[0]?.FUNCAO || "");
      }
    } catch (error) {
      console.error(error);
    }
  }

  let optionFuncoes;
  if (filial) {
    optionFuncoes = listaFuncoes
      .filter((item) => item.FILIAL === filial.substring(3, 7))
      .map((item) => ({
        value: `${item.FUNCAO_ID} - ${item.FUNCAO}`,
        label: `${item.FUNCAO_ID} - ${item.FUNCAO}`,
      }));
  }

  function autoCentroCusto(e) {
    e.preventDefault();
    if (admissaoNovaFuncao) {
      const funcaoId = admissaoNovaFuncao?.value.split(" - ")[0];

      const centro_custo = listaFuncoes.filter(
        (item) =>
          item.FUNCAO_ID === funcaoId && item.FILIAL === filial.substring(3, 7)
      );

      setAdmissaoCentroCusto(
        `${centro_custo[0]?.CENTRO_CUSTO_ID} - ${centro_custo[0]?.CENTRO_CUSTO}`
      );
    }

    if (mudancaFuncaoNovaFuncao) {
      const funcaoId = mudancaFuncaoNovaFuncao?.value.split(" - ")[0];

      const centro_custo = listaFuncoes.filter(
        (item) =>
          item.FUNCAO_ID === funcaoId && item.FILIAL === filial.substring(3, 7)
      );

      setMudancaFuncaoNovoCC(
        `${centro_custo[0]?.CENTRO_CUSTO_ID} - ${centro_custo[0]?.CENTRO_CUSTO}`
      );
    }
  }

  const showNoticePeriod = demissao === 2 || demissao === 4 || demissao === 10;

  // const competence_condition_view =
  //   admissaoArray?.id === atendimento ||
  //   demissaoArray?.id === atendimento ||
  //   mudancaFuncaoArray?.id === atendimento ||
  //   atendimento === 4 ||
  //   atendimento === 5;

  const typesServices = [6, 7, 8, 9, 12];

  return (
    <>
      <ToastContainer />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Form noValidate>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {" "}
                <Typography component="h1" variant="h5" align="center">
                  Iniciar Atendimento
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel
                    ref={inputLabel}
                    color="primary"
                    aria-label="Selecione a Filial"
                    variant="outlined"
                  >
                    Selecione a Filial
                  </InputLabel>

                  <Select
                    label="Selecione a Filial"
                    value={filial}
                    onChange={handleChangeFilial}
                    labelwidth={labelWidth}
                    name="filial"
                  >
                    <MenuItem value="">
                      <em>Nenhum</em>
                    </MenuItem>
                    {listFilial.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={`${item.GRUPO}-${item.COD_FILIAL} ${item.NOME_FILIAL}`}
                      >
                        {`${item.GRUPO} - ${item.COD_FILIAL} - ${item.NOME_FILIAL}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel
                    ref={inputLabel}
                    id="demo-simple-select-outlined-label"
                    color="error"
                  >
                    Tipo de Atendimento
                  </InputLabel>
                  <Select
                    label="Tipo de Atendimento"
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={atendimento}
                    onChange={handleChangeAtendimento}
                    labelwidth={labelWidth}
                    color="error"
                  >
                    <MenuItem value="">
                      <em>Nenhum</em>
                    </MenuItem>

                    {tiposAtendimento.map((atendimento) => (
                      <MenuItem key={atendimento.id} value={atendimento.id}>
                        {atendimento.nome_atendimento}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {admissaoArray?.id === atendimento && (
                <>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel
                        ref={inputLabel}
                        id="demo-simple-select-outlined-label"
                        color="secondary"
                      >
                        Tipo de movimentação
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-outlined-label"
                        label="Tipo de movimentação"
                        id="demo-simple-select-outlined"
                        value={admissao}
                        onChange={handleChangeAdmissao}
                        labelwidth={labelWidth}
                        color="secondary"
                        name="admissao"
                      >
                        <MenuItem value="">
                          <em>Nenhum</em>
                        </MenuItem>
                        {tiposAdmissao.map((admissao) => (
                          <MenuItem key={admissao.id} value={admissao.id}>
                            {admissao.nome_tipo_admissao}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {!!admissao && (
                    <>
                      {admissao === 1 ||
                        admissao === 2 ||
                        (admissao === 3 && (
                          <Grid item xs={12}>
                            <TextField
                              name="matricula"
                              id="matricula"
                              variant="outlined"
                              fullWidth
                              color="secondary"
                              label="Matrícula"
                              required
                              value={admissaoMatricula}
                              onChange={(event) =>
                                setAdmissaoMatricula(
                                  event.target.value.replaceAll(/\D/g, "")
                                )
                              }
                              onBlur={(e) =>
                                ConsultaMatricula(e, admissaoMatricula)
                              }
                            />
                          </Grid>
                        ))}

                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          // //margin="normal"
                          required
                          fullWidth
                          id="funcao"
                          color="secondary"
                          label="Função"
                          name="funcao"
                          autoFocus
                          value={admissaoFuncao}
                          onChange={(event) =>
                            setAdmissaoFuncao(event.target.value.toUpperCase())
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          // //margin="normal"
                          required
                          fullWidth
                          id="user"
                          color="secondary"
                          label={
                            AumentoQuadroEfet.id === admissao ||
                            AumentoQuadroTemp.id === admissao
                              ? "Motivo"
                              : "De quem?"
                          }
                          name="user"
                          autoFocus
                          value={
                            AumentoQuadroEfet.id === admissao ||
                            AumentoQuadroTemp.id === admissao
                              ? motivoAdmissao
                              : funcionarioSubstituido
                          }
                          onChange={(event) =>
                            AumentoQuadroEfet.id === admissao ||
                            AumentoQuadroTemp.id === admissao
                              ? setMotivoAdmissao(event.target.value)
                              : setFuncionarioSubstituido(
                                  event.target.value.toUpperCase()
                                )
                          }
                        />
                      </Grid>
                      {admissao === 3 && (
                        <Grid item xs={12}>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel
                              ref={inputLabel}
                              color="secondary"
                              aria-label="Filial de Origem"
                              variant="outlined"
                            >
                              Filial de Origem
                            </InputLabel>

                            <Select
                              label=" Filial de Origem"
                              value={admissaoFilialOrigem}
                              onChange={(event) =>
                                setAdmissaoFilialOrigem(event.target.value)
                              }
                              labelwidth={labelWidth}
                              color="secondary"
                              name="filial_origem"
                            >
                              <MenuItem value="">
                                <em>Nenhum</em>
                              </MenuItem>
                              {listFilial.map((item, index) => (
                                <MenuItem
                                  key={index}
                                  value={`${item.GRUPO}-${item.COD_FILIAL} ${item.NOME_FILIAL}`}
                                >
                                  {`${item.GRUPO} - ${item.COD_FILIAL} - ${item.NOME_FILIAL}`}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}

                      {admissao === 1 && (
                        <Grid item xs={12}>
                          <TextField
                            id="novo_funcionario"
                            name="novo_funcionario"
                            variant="outlined"
                            label="Novo Funcionário"
                            fullWidth
                            color="secondary"
                            required
                            value={admissaoNovoFuncionario}
                            onChange={(event) =>
                              setAdmissaoNovoFuncionario(
                                event.target.value.toUpperCase().trimStart()
                              )
                            }
                          />
                        </Grid>
                      )}

                      {admissao === 2 ||
                        (admissao === 1 && (
                          <>
                            <Grid item xs={12}>
                              <SelectReact
                                className="basic-single"
                                name="nova_funcao"
                                id="nova_funcao"
                                placeholder={
                                  admissao === 1 ? "Função" : "Nova Função"
                                }
                                required
                                isSearchable
                                options={optionFuncoes}
                                onBlur={autoCentroCusto}
                                value={admissaoNovaFuncao}
                                onChange={(value) =>
                                  setAdmissaoNovaFuncao(value)
                                }
                                styles={{
                                  option: (provided, state) => ({
                                    ...provided,
                                    color: state.isSelected ? "white" : "black",
                                    backgroundColor: state.isSelected
                                      ? red[600]
                                      : "white",
                                    "&:hover": { backgroundColor: "#D3D3D3" },
                                    zIndex: 1,
                                  }),
                                  control: (provided) => ({
                                    ...provided,
                                    borderColor: "lightgray",
                                    boxShadow: "none",
                                    "&:hover": { borderColor: red[600] },
                                    height: "4rem",
                                    backgroundColor: "#FAFAFA",
                                  }),
                                  placeholder: (provider) => ({
                                    ...provider,
                                    fontSize: "15px",
                                  }),
                                }}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                name="centro_custo_id"
                                id="centro_custo_id"
                                label="Centro de Custo"
                                variant="outlined"
                                required
                                color="secondary"
                                value={admissaoCentroCusto}
                                fullWidth
                                onChange={(e) =>
                                  setAdmissaoCentroCusto(
                                    e.target.value.replaceAll(/\D/g, "")
                                  )
                                }
                              />
                            </Grid>
                          </>
                        ))}
                    </>
                  )}
                </>
              )}

              {demissaoArray?.id === atendimento && (
                <>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel
                        ref={inputLabel}
                        id="demo-simple-select-outlined-label"
                        color="secondary"
                      >
                        Tipo de movimentação
                      </InputLabel>
                      <Select
                        label="Tipo de movimentação"
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={demissao}
                        onChange={handleChangeDemissao}
                        labelwidth={labelWidth}
                        color="secondary"
                        name="demissao"
                      >
                        <MenuItem value="">
                          <em>Nenhum</em>
                        </MenuItem>
                        {tiposDemissao.map((demissao) => (
                          <MenuItem
                            key={String(demissao.id)}
                            value={demissao.id}
                          >
                            {demissao.nome_tipo_demissao}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {!!demissao && (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="user"
                          color="secondary"
                          label="Matrícula"
                          name="user"
                          autoFocus
                          value={demissaoMatricula}
                          onChange={(event) =>
                            setDemissaoMatricula(event.target.value)
                          }
                          onBlur={(e) =>
                            ConsultaMatricula(e, demissaoMatricula)
                          }
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="user"
                          color="secondary"
                          label="Nome Funcionário"
                          name="user"
                          autoFocus
                          value={demissaoNomeFuncioario}
                          onChange={(event) =>
                            setDemissaoNomeFuncionario(event.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="user"
                          color="secondary"
                          label="Função"
                          name="user"
                          autoFocus
                          value={demissaoFuncao}
                          onChange={(event) =>
                            setDemissaoFuncao(event.target.value)
                          }
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          locale={pt}
                        >
                          <KeyboardDatePicker
                            autoOk
                            fullWidth
                            // //margin="normal"
                            variant="inline"
                            color="secondary"
                            inputVariant="outlined"
                            label="Data demissão"
                            format="dd/MM/yyyy"
                            value={demissaoDataDesligamento}
                            InputAdornmentProps={{ position: "start" }}
                            onChange={(date) =>
                              setDemissaoDataDesligamento(date)
                            }
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>

                      {showNoticePeriod && (
                        <>
                          <Grid item xs={6}>
                            <HorizontalLineWithText text="Período do Aviso" />
                          </Grid>

                          <Grid item xs={12}>
                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              locale={pt}
                            >
                              <KeyboardDatePicker
                                autoOk
                                fullWidth
                                // color="secondary"
                                inputVariant="outlined"
                                label="Data Início"
                                format="dd/MM/yyyy"
                                value={demissaoAvisoInicio}
                                InputAdornmentProps={{ position: "start" }}
                                onChange={(date) =>
                                  setDemissaoAvisoInicio(date)
                                }
                                color="secondary"
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>

                          <Grid item xs={12}>
                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              locale={pt}
                            >
                              <KeyboardDatePicker
                                autoOk
                                fullWidth
                                variant="inline"
                                color="secondary"
                                inputVariant="outlined"
                                label="Data Final"
                                format="dd/MM/yyyy"
                                value={demissaoAvisoFim}
                                InputAdornmentProps={{ position: "start" }}
                                onChange={(date) => setDemissaoAvisoFim(date)}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              {mudancaFuncaoArray?.id === atendimento && (
                <>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel
                        ref={inputLabel}
                        id="demo-simple-select-outlined-label"
                        color="secondary"
                      >
                        Tipo de movimentação
                      </InputLabel>

                      <Select
                        label="Tipo de movimentação"
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={mudancaFuncao}
                        onChange={handleChangeMucancaFuncao}
                        color="secondary"
                        name="admissao"
                      >
                        <MenuItem value="">
                          <em>Nenhum</em>
                        </MenuItem>
                        {tiposMudancaFuncao.map((mudancaFuncao) => (
                          <MenuItem
                            key={mudancaFuncao.id}
                            value={mudancaFuncao.id}
                          >
                            {mudancaFuncao.nome_tipo_mudanca_funcao}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {!!mudancaFuncao && (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          //margin="normal"
                          required
                          fullWidth
                          id="user"
                          color="secondary"
                          label="Matrícula"
                          name="user"
                          autoFocus
                          value={mudancaFuncaoMatricula}
                          onChange={(event) =>
                            setMudancaFuncaoMatricula(event.target.value)
                          }
                          onBlur={(e) =>
                            ConsultaMatricula(e, mudancaFuncaoMatricula)
                          }
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          //margin="normal"
                          required
                          fullWidth
                          id="user"
                          color="secondary"
                          label="Nome Funcionário"
                          name="user"
                          autoFocus
                          value={mudancaFuncaoNome}
                          onChange={(event) =>
                            setMudancaFuncaoNome(event.target.value)
                          }
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          // //margin="normal"
                          required
                          fullWidth
                          id="funcao"
                          color="secondary"
                          label="Função"
                          name="funcao"
                          autoFocus
                          value={mudancaFuncaoFuncao}
                          onChange={(event) =>
                            setMudancaFuncaoFuncao(
                              event.target.value.toUpperCase()
                            )
                          }
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          name="m_centro_custo_id"
                          id="m_centro_custo_id"
                          label="Centro de Custo"
                          variant="outlined"
                          required
                          color="secondary"
                          value={mudancaFuncaoCentroCusto}
                          fullWidth
                          // onChange={(e) =>
                          //   setMudancaFuncaoCentroCusto(
                          //     e.target.value.replaceAll(/\D/g, "")
                          //   )
                          // }
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <HorizontalLineWithText text="Nova função" />
                      </Grid>

                      <Grid item xs={12}>
                        <SelectReact
                          className="basic-single"
                          name="nova_funcao"
                          id="nova_funcao"
                          placeholder="Função"
                          required
                          isSearchable
                          options={optionFuncoes}
                          onBlur={autoCentroCusto}
                          value={mudancaFuncaoNovaFuncao}
                          onChange={(value) =>
                            setMudancaFuncaoNovaFuncao(value)
                          }
                          styles={{
                            option: (provided, state) => ({
                              ...provided,
                              color: state.isSelected ? "white" : "black",
                              backgroundColor: state.isSelected
                                ? red[600]
                                : "white",
                              "&:hover": { backgroundColor: "#D3D3D3" },
                              zIndex: 1,
                            }),
                            control: (provided) => ({
                              ...provided,
                              borderColor: "lightgray",
                              boxShadow: "none",
                              "&:hover": { borderColor: red[600] },
                              height: "4rem",
                              backgroundColor: "#FAFAFA",
                            }),
                            placeholder: (provider) => ({
                              ...provider,
                              fontSize: "15px",
                            }),
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          id="novo_centro_custo"
                          name="novo_centro_custo"
                          label="Centro Custo"
                          variant="outlined"
                          value={mudancaFuncaoNovoCC}
                          onChange={(e) =>
                            setMudancaFuncaoNovoCC(e.target.value)
                          }
                          required={true}
                          fullWidth
                          color="secondary"
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}

              {atendimento === 4 && ( // Sanção disciplinar
                <>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel
                        ref={inputLabel}
                        id="demo-simple-select-outlined-label"
                        color="secondary"
                      >
                        Tipo de movimentação
                      </InputLabel>

                      <Select
                        label="Tipo de movimentação"
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={sancaoTipo}
                        onChange={(e) => setSancaoTipo(e.target.value)}
                        color="secondary"
                        name="admissao"
                      >
                        <MenuItem value="">
                          <em>Nenhum</em>
                        </MenuItem>
                        <MenuItem value={"1 - Advertência"}>
                          Advertência
                        </MenuItem>
                        <MenuItem value={"2 - Suspensão"}>Suspensão</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name="matricula"
                      id="matricula"
                      variant="outlined"
                      fullWidth
                      color="secondary"
                      label="Matrícula"
                      required
                      value={sancaoMatricula}
                      onChange={(event) =>
                        setSancaoMatricula(
                          event.target.value.replaceAll(/\D/g, "")
                        )
                      }
                      onBlur={(e) => ConsultaMatricula(e, sancaoMatricula)}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name="funcionario"
                      id="funcionario"
                      label="Funcionário"
                      value={sancaoFuncionario}
                      onChange={(e) => setSancaoFuncionario(e.target.value)}
                      required
                      color="secondary"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      // //margin="normal"
                      required
                      fullWidth
                      id="funcao"
                      color="secondary"
                      label="Função"
                      name="funcao"
                      autoFocus
                      value={sancaoFuncao}
                      onChange={(event) =>
                        setSancaoFuncao(event.target.value.toUpperCase())
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                      <KeyboardDatePicker
                        autoOk
                        fullWidth
                        // //margin="normal"
                        variant="inline"
                        color="secondary"
                        inputVariant="outlined"
                        label="Aplicação"
                        format="dd/MM/yyyy"
                        value={sancaoAplicacao}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={(date) => setSancaoAplicacao(date)}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                      <KeyboardDatePicker
                        autoOk
                        fullWidth
                        // //margin="normal"
                        variant="inline"
                        color="secondary"
                        inputVariant="outlined"
                        label="Retorno"
                        format="dd/MM/yyyy"
                        value={sancaoRetorno}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={(date) => setSancaoRetorno(date)}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </>
              )}

              {typesServices.includes(atendimento) && (
                <Grid item xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                    <KeyboardDatePicker
                      autoOk
                      fullWidth
                      views={["year", "month"]}
                      variant="inline"
                      color="secondary"
                      inputVariant="outlined"
                      label="Competência"
                      format="MM/yyyy"
                      value={competencia}
                      InputAdornmentProps={{ position: "start" }}
                      onChange={(date) => setCompetencia(date)}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              )}

              {/* FECHAMENTO DE PONTO */}
              {atendimento === 5 && (
                <>
                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                      <KeyboardDatePicker
                        autoOk
                        fullWidth
                        // views={["year", "month"]}
                        variant="inline"
                        color="secondary"
                        inputVariant="outlined"
                        label="Inicío"
                        format="dd/MM/yyyy"
                        value={folhaPontoInicio}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={(date) => setFolhaPontoInicio(date)}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                      <KeyboardDatePicker
                        autoOk
                        fullWidth
                        variant="inline"
                        color="secondary"
                        inputVariant="outlined"
                        label="Fim"
                        format="dd/MM/yyyy"
                        value={folhaPontoFim}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={(date) => setFolhaPontoFim(date)}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </>
              )}

              {/* ATESTADOS */}
              {atendimento === 13 && (
                <>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel
                        ref={inputLabel}
                        id="demo-simple-select-outlined-label"
                        color="secondary"
                      >
                        Tipo de movimentação
                      </InputLabel>

                      <Select
                        label="Tipo de movimentação"
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={tipoAtestados}
                        onChange={(e) => setTipoAtestados(e.target.value)}
                        color="secondary"
                        name="tipo_movimentacao_atestados"
                      >
                        <MenuItem value={"Atestado acima de 3 dias"}>
                          Atestado acima de 3 dias
                        </MenuItem>
                        <MenuItem value={"Licença Maternidade"}>
                          Licença Maternidade
                        </MenuItem>
                        <MenuItem value={"Afastamento INSS"}>
                          Afastamento INSS
                        </MenuItem>
                        <MenuItem value={"Outros"}>Outros</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {tipoAtestados === "Outros" && (
                    <Grid item xs={12}>
                      <TextField
                        id="outros"
                        label="Outros"
                        variant="outlined"
                        fullWidth
                        value={atestadosOutros}
                        onChange={(e) => setAtestadosOutros(e.target.value)}
                        required
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <TextField
                      id="matricula"
                      label="Matrícula"
                      variant="outlined"
                      fullWidth
                      value={atestadosMatricula}
                      onChange={(e) => setAtestadosMatricula(e.target.value)}
                      required
                      onBlur={(e) => ConsultaMatricula(e, atestadosMatricula)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="nome"
                      label="Nome"
                      variant="outlined"
                      fullWidth
                      value={atestadosNome}
                      onChange={(e) => setAtestadosNome(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="funcao"
                      label="Função"
                      variant="outlined"
                      fullWidth
                      value={atestadosFuncao}
                      onChange={(e) => setAtestadosFuncao(e.target.value)}
                      required
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                      <KeyboardDatePicker
                        autoOk
                        fullWidth
                        // //margin="normal"
                        variant="inline"
                        color="secondary"
                        inputVariant="outlined"
                        label="Aplicação"
                        format="dd/MM/yyyy"
                        value={atestadosAplicacao}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={(date) => setAtestadosAplicacao(date)}
                        required
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                      <KeyboardDatePicker
                        autoOk
                        fullWidth
                        // //margin="normal"
                        variant="inline"
                        color="secondary"
                        inputVariant="outlined"
                        label="Retorno"
                        format="dd/MM/yyyy"
                        value={atestadosRetorno}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={(date) => setAtestadosRetorno(date)}
                        required
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </>
              )}

              {/* PREMIAÇÕES */}
              {atendimento === 14 && (
                <>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel
                        ref={inputLabel}
                        id="demo-simple-select-outlined-label"
                        color="secondary"
                      >
                        Tipo de movimentação
                      </InputLabel>

                      <Select
                        label="Tipo de movimentação"
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={tipoPremiacao}
                        onChange={(e) => setTipoPremiacao(e.target.value)}
                        color="secondary"
                        name="tipo_movimentacao_premiacao"
                      >
                        <MenuItem value={"VEI – Premiação Caixa"}>
                          VEI – Premiação Caixa
                        </MenuItem>
                        <MenuItem value={"VEX – Premiação do Lojas"}>
                          VEX – Premiação do Lojas
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                      <KeyboardDatePicker
                        autoOk
                        fullWidth
                        views={["year", "month"]}
                        variant="inline"
                        color="secondary"
                        inputVariant="outlined"
                        label="Competência"
                        format="MM/yyyy"
                        value={competencia}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={(date) => setCompetencia(date)}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </>
              )}

              {atendimento === 15 && (
                <>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel
                        ref={inputLabel}
                        id="demo-simple-select-outlined-label"
                        color="secondary"
                      >
                        Tipo de Movimentação
                      </InputLabel>

                      <Select
                        label="Tipo de movimentação"
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={tipoBonus}
                        onChange={(e) => setTipoBonus(e.target.value)}
                        color="secondary"
                        name="tipo_movimentacao_bonus"
                      >
                        <MenuItem value={"VEB- bônus"}>VEB- bônus</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel
                        ref={inputLabel}
                        id="demo-simple-select-outlined-label"
                        color="secondary"
                      >
                        Tipo de Plano
                      </InputLabel>

                      <Select
                        label="Tipo de Plano"
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={bonusPlano}
                        onChange={(e) => setBonusPlano(e.target.value)}
                        color="secondary"
                        name="tipo_plano"
                      >
                        <MenuItem value={"Anual"}>Anual</MenuItem>
                        <MenuItem value={"Semestral"}>Semestral</MenuItem>
                        <MenuItem value={"Trimestral"}>Trimestral</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                      <KeyboardDatePicker
                        autoOk
                        fullWidth
                        views={["year", "month"]}
                        variant="inline"
                        color="secondary"
                        inputVariant="outlined"
                        label="Competência"
                        format="MM/yyyy"
                        value={competencia}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={(date) => setCompetencia(date)}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </>
              )}

              {/* BENEFICIOS */}
              {atendimento === 16 && (
                <>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel
                        ref={inputLabel}
                        id="demo-simple-select-outlined-label"
                        color="secondary"
                      >
                        Tipo de Movimentação
                      </InputLabel>

                      <Select
                        label="Tipo de Movimentação"
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={tipoBeneficio}
                        onChange={(e) => setTipoBeneficio(e.target.value)}
                        color="secondary"
                        name="tipo_movimentacao"
                      >
                        <MenuItem value={"VA –Alimentação"}>
                          VA –Alimentação
                        </MenuItem>
                        <MenuItem value={"VT – Transporte"}>
                          {" "}
                          VT – Transporte
                        </MenuItem>
                        <MenuItem value={"VC – Combustível"}>
                          VC – Combustível
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                      <KeyboardDatePicker
                        autoOk
                        fullWidth
                        views={["year", "month"]}
                        variant="inline"
                        color="secondary"
                        inputVariant="outlined"
                        label="Competência"
                        format="MM/yyyy"
                        value={competencia}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={(date) => setCompetencia(date)}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </>
              )}

              {/* DIVERSOS */}
              {atendimento === 17 && (
                <>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel
                        ref={inputLabel}
                        id="demo-simple-select-outlined-label"
                        color="secondary"
                      >
                        Tipo de movimentação
                      </InputLabel>

                      <Select
                        label="Tipo de movimentação"
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={tipoDiversos}
                        onChange={(e) => setTipoDiversos(e.target.value)}
                        color="secondary"
                        name="tipo_movimentacao_diversos"
                      >
                        <MenuItem value={"Coberturas de férias"}>
                          Coberturas de férias
                        </MenuItem>
                        <MenuItem value={"Acúmulos Função"}>
                          Acúmulos Função
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="matricula"
                      label="Matrícula"
                      variant="outlined"
                      fullWidth
                      value={diversosMatricula}
                      onChange={(e) => setDiversosMatricula(e.target.value)}
                      required
                      onBlur={(e) => ConsultaMatricula(e, diversosMatricula)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="nome"
                      label="Nome"
                      variant="outlined"
                      fullWidth
                      value={diversosNome}
                      onChange={(e) => setDiversosNome(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="funcao"
                      label="Função"
                      variant="outlined"
                      fullWidth
                      value={diversosFuncao}
                      onChange={(e) => setDiversosFuncao(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                      <KeyboardDatePicker
                        autoOk
                        fullWidth
                        views={["year", "month"]}
                        variant="inline"
                        color="secondary"
                        inputVariant="outlined"
                        label="Competência"
                        format="MM/yyyy"
                        value={competencia}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={(date) => setCompetencia(date)}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="user"
                  color="secondary"
                  label="Descrição"
                  name="user"
                  autoComplete="Descrição"
                  autoFocus
                  value={descricao}
                  multiline
                  minRows={5}
                  onChange={(event) => setDescricao(event.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <ButtonNovo
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleHistoryAtendimento}
                >
                  Iniciar
                </ButtonNovo>
              </Grid>

              <Grid item xs={12}>
                <ButtonNovo
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleHistoryBack}
                >
                  Voltar
                </ButtonNovo>
              </Grid>
            </Grid>
          </Form>
        </div>
      </Container>
    </>
  );
}
