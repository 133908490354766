import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { MdCheckCircle, MdError, MdLink } from "react-icons/md";

import pdf from "../../assets/pdf.png";
import word from "../../assets/word.svg";
import excel from "../../assets/excel.svg";

import { Container, FileInfo, Preview } from "./styles";
const re = /(?:\.([^.]+))?$/;
//uploadedFile.file.type === 'application/pdf'

function icon(file) {
  if (re.exec(file.name)[1] === "pdf") {
    return pdf;
  }
  if (re.exec(file.name)[1] === "doc" || re.exec(file.name)[1] === "docx") {
    return word;
  }
  if (re.exec(file.name)[1] === "xlsx") {
    return excel;
  }
  return file.preview;
}

const dataLocalStorage = JSON.parse(localStorage.getItem("login"));

const gestor = dataLocalStorage === null ? false : dataLocalStorage.gestor;

const userAuthId = [72, 89, 111];

const userAuthDeleteComm = userAuthId.includes(dataLocalStorage?.id);

const FileList = ({ files, onDelete, onDelFile }) => (
  <Container>
    {files.map((uploadedFile) => (
      <li key={uploadedFile.id}>
        <FileInfo>
          <Preview src={icon(uploadedFile)} />
          <div>
            <strong>
              {uploadedFile.name.length > 30
                ? uploadedFile.name.substring(0, 10) + "..."
                : uploadedFile.name}
            </strong>
            <span>
              {uploadedFile.readableSize +
                " | " +
                uploadedFile.data +
                " | " +
                uploadedFile.user}{" "}
              {!!uploadedFile.url &&
                (onDelFile === true ? (
                  <button
                    onClick={() =>
                      onDelete(uploadedFile.id, uploadedFile.id_api)
                    }
                    disabled={!(gestor || userAuthDeleteComm)}
                  >
                    Excluir
                  </button>
                ) : (
                  ""
                ))}
            </span>
          </div>
        </FileInfo>

        <div>
          {!uploadedFile.uploaded && !uploadedFile.error && (
            <CircularProgressbar
              styles={{
                root: { width: 24 },
                path: { stroke: "#DA2C38" },
              }}
              strokeWidth={10}
              percentage={uploadedFile.progress}
            />
          )}

          {uploadedFile.url && (
            <a
              href={uploadedFile.url.replace(
                "http://static.rmti7.com/",
                "https://storage.googleapis.com/static.rmti7.com/"
              )}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MdLink style={{ marginRight: 8 }} size={24} color="#222" />
            </a>
          )}

          {uploadedFile.uploaded && <MdCheckCircle size={24} color="#78e5d5" />}
          {uploadedFile.error && <MdError size={24} color="#e57878" />}
        </div>
      </li>
    ))}
  </Container>
);

export default FileList;
