import React, { useState } from "react";
import { useDispatch } from "react-redux";

import api from "../../services/api";
//import Cookies from 'universal-cookie';

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import logo from "../../logo.png";

import ButtonNovo from "../../component/ButtonRed";
import Copyright from "../../component/Copyright";
import history from "../../services/history";
import { Grid } from "@material-ui/core";

//const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login(/*{ history }*/) {
  const classes = useStyles();
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [login, setLogin] = useState(true);

  const dispatch = useDispatch();

  async function handleSubmit(event) {
    event.preventDefault();
    toast.configure();
    const errorLogin = () => toast.error("😓 Usuário ou Senha inválidos");
    const errorPost = () =>
      toast.error("😭Falha ao conectar com Servidor backend");
    const errorProtheus = () =>
      toast.error("😭Falha ao conectar com Servidor Protheus");
    const loginSuccess = () =>
      toast.success("😎 Tudo certo! Estamos redirecionando para o dashborad.", {
        autoClose: 2000,
      });

    dispatch({ type: "REMOVE_STATE" });

    if (login) {
      setLogin(false);
      localStorage.removeItem("login");
      await api
        .post("/login", {
          CLOGIN: user,
          CPASSWORD: pass,
        })
        .then(function a(response) {
          if (response.data.fail === "Falha ao comunicar com Protheus") {
            setLogin(true);
            errorProtheus();
          } else if (response.data.fail === "login invalido") {
            setLogin(true);
            errorLogin();
          } else {
            loginSuccess();
            setTimeout(() => {
              setLogin(true);

              //let cod_protheus = response.data.slice(0, 6);
              //let cod_protheus = response.data.cod_protheus;
              //let time = new Date();

              //time.setMinutes( time.getMinutes() + 30);

              //cookies.set('cod_protheus', cod_protheus, { path: '/', expires: time  });

              localStorage.setItem("login", JSON.stringify(response.data));
              //let tst = JSON.parse(localStorage.getItem('login'));
              history.push("/dashboard");
            }, 500);
            //history.push('/atendimento');
          }
        })
        .catch(function (error) {
          console.log(error);
          errorPost();
          setLogin(true);
        });
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} className="App-logo" alt="logo" height="100" />
        <Typography component="h1" variant="h5">
          Portal RH Rede Marajó
        </Typography>

        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                //margin="normal"
                required
                fullWidth
                id="user"
                color="secondary"
                label="Usuário"
                name="user"
                autoComplete="Usuário"
                autoFocus
                value={user}
                onChange={(event) => setUser(event.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                //margin="normal"
                required
                fullWidth
                color="secondary"
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
                value={pass}
                onChange={(event) => setPass(event.target.value)}
              />
            </Grid>
          </Grid>

          <ButtonNovo
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Entrar
          </ButtonNovo>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
