import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Form } from "@unform/web";
import React, { useState } from "react";
import Swal from "sweetalert2";
import ButtonNovo from "../../component/ButtonRed";
import api from "../../services/api";
import history from "../../services/history";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
    display: "flex",
    flexDirection: "column",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

//const cookies = new Cookies();

export default function Atendimento(/*{history}*/) {
  const classes = useStyles();

  const [ticket, setTicket] = useState("");

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom",
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  function handleHistoryBack(e) {
    e.preventDefault();
    history.push("/dashboard");
  }

  async function handleOpenTicket(e) {
    e.preventDefault();

    try {
      await api.put(`/openTicket/${ticket}`);
      Toast.fire({
        icon: "success",
        title: "Atendimento reaberto com sucesso!",
      });
      history.push(`/interagiratendimento/${ticket}`);
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: "Erro ao abrir o atendimento",
      });
    }
  }

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5" style={{ marginTop: 30 }}>
            Reabrir Atendimento Fechado
          </Typography>

          <Form className={classes.form} noValidate>
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                variant="outlined"
                //margin="normal"
                required
                fullWidth
                id="ticket"
                color="secondary"
                label="Número do Atendimento"
                name="ticket"
                autoComplete="off"
                autoFocus
                value={ticket}
                onChange={(event) => setTicket(event.target.value)}
              />

              <ButtonNovo
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleOpenTicket}
                className={classes.submit}
              >
                Reabrir
              </ButtonNovo>
              <ButtonNovo
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleHistoryBack}
                className={classes.submit}
              >
                Voltar
              </ButtonNovo>
            </FormControl>
          </Form>
        </div>
      </Container>
    </>
  );
}
