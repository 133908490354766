import React, {useEffect} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import MaterialTable from 'material-table';

import ButtonNovo from '../../component/ButtonRed';
import api from '../../services/api';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function InteragirAtendimento( {history } ) {
  const classes = useStyles();

  const tableHeader = {
    columns: [
      { title: '#ID', field: 'id' },
      { title: 'Filial', field: 'filial' },
      { title: 'Tipo de Solicitação', field: 'tipo_atendimento.nome_atendimento' },
      { title: 'Solicitante', field: 'user_id_solicitante.nome' },
      { title: 'Data', field: 'data' },
      { title: 'Responsável', field: 'user_id_responsavel.nome' },
      { title: 'Descrição', field: 'descricao' },
      { title: 'Status', field: 'status'},
   
    ]};

  const [tableData, setTableData] = React.useState([]);
  const dataLocalStorage = JSON.parse(localStorage.getItem('login'));
  const user_responsavel = dataLocalStorage.id;
 
  useEffect(() => {

    async function fetchData() {
        const response = await api.get(`/atendimentoagente/${user_responsavel}`);
        setTableData(response);
    }
    fetchData();

  }, [user_responsavel]);

  function handleHistoryBack (e) {
    e.preventDefault();
    history.push('/dashboard');
  }

  function handleAtendimentoId (e, id) {
    e.preventDefault();
    history.push(`/interagiratendimento/${id}`)
  }

  return (
    <>
    <Container component="main" maxWidth="lg">
      <CssBaseline />
        <div className={classes.paper}>
            {/*<img src={logo} className="App-logo" alt="logo" height="100" />*/}
            <form className={classes.form} noValidate>
                <MaterialTable
                    title="Atendimentos Atribuídos a mim"
                    isLoading={!tableData.data}
                    columns={tableHeader.columns}
                    data={tableData.data}
                    actions={[
                    {
                        icon: 'search',
                        //icon: 'edit',
                        tooltip: 'Visulizar',
                        onClick: (event, rowData) => handleAtendimentoId(event, rowData.id)
                    }
                    ]}
                    localization={{
                        header: {
                            actions: 'Visualizar',
                        },
                        toolbar: {
                            searchPlaceholder: 'Pesquisar' 
                        },
                        body: {
                        emptyDataSourceMessage: 'Não há registros a serem exibidos',
                            editRow: {
                                deleteText: "Realmente Deseja Excluir?"
                        }},
                        pagination: {
                        labelRowsSelect: "Linhas",
                        labelDisplayedRows: '{from}-{to} de {count}',
                        firstTooltip: 'Primeira Página',
                        lastTooltip: 'Última Página',
                        nextTooltip: 'Próxima Página',
                        previousTooltip: 'Página Anterior'
                        },
                    }}
                    options={{
                        actionsColumnIndex: -1,
                        pageSizeOptions: [5, 10],
                        paginationType: 'stepped'
                    }}
                    /*editable={{          
                        onRowUpdate: (newData, oldData) =>
                        new Promise(resolve => {
                            setTimeout(() => {
                            resolve();
                            if (oldData) {
                                //setState(prevState => {
                                setTableData(prevState => {
                                const data = [...prevState.data];
                                data[data.indexOf(oldData)] = newData;
                                return { ...prevState, data };
                                });
                            }
                            }, 600);
                        }),
                        onRowDelete: oldData =>
                        new Promise(resolve => {
                            setTimeout(() => {
                            resolve();
                            //setState(prevState => {
                            setTableData(prevState => {
                                const data = [...prevState.data];
                                data.splice(data.indexOf(oldData), 1);
                                return { ...prevState, data };
                            });
                            }, 600);
                        }),
                    }}*/
                />
                <ButtonNovo
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleHistoryBack}
                    className={classes.submit}
                >
                    Voltar
                </ButtonNovo>
        </form>
        </div>
    </Container>
    </>
  );
}