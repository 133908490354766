/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Backdrop,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  CssBaseline,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import { Form } from "@unform/web";
import UserTable from "./UserTable";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
    display: "flex",
    flexDirection: "column",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#ffff",
  },
}));

export default function FaltasCaixa() {
  const classes = useStyles();
  const inputLabel = React.useRef(null);
  const [filial, setFilial] = useState("");
  const [companies, setCompanies] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [finalDate, setFinalDate] = useState(
    new Date().toISOString().substring(0, 10)
  );

  const [tableDate, setTableDate] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    async function Companies() {
      try {
        const response = await api.get(`companies`);
        setCompanies(response?.data?.companies);
      } catch (error) {
        console.log(error);
      }
    }
    Companies();
  }, []);

  async function handleSearchFaults() {
    setOpen(true);
    try {
      const response = await api.post(`lackOfCash`, {
        filial: filial.split("-")[1],
        startDate: startDate.replace(/-/g, ""),
        finalDate: finalDate.replace(/-/g, ""),
      });
      setTableDate(response.data);
      setOpen(false);

      if (response.data.length === 0) {
        Swal.fire({
          title: "Ops...",
          text: "Não encontramos faltas de caixa no período selecionado.",
          icon: "warning",
        });
      }
    } catch (error) {
      setOpen(false);
      console.log(error);
    } finally {
      setOpen(false);
    }
  }

  const headers = [
    {
      label: "Filial",
      key: "FILIAL",
    },
    { label: "Periodo", key: "PERIODO" },
    { label: "Matricula", key: "MATRICULA" },
    { label: "Codigo", key: "CODIGO" },
    { label: "Valor", key: "VALOR" },
  ];

  if (startDate > finalDate) {
    toast.warn("A data início não pode ser maior que a data final!", {
      position: "top-right",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      autoClose: 2500,
    });
    setStartDate(new Date().toISOString().substring(0, 10));
  }

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5" style={{ marginTop: 30 }}>
            Faltas de caixa
          </Typography>
          <br />
          <Form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel
                    ref={inputLabel}
                    id="demo-simple-select-outlined-label"
                    color="secondary"
                  >
                    Selecione a Filial
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={filial}
                    onChange={(e) => setFilial(e.target.value)}
                    color="secondary"
                    name="filial"
                    required
                    label="Selecione a Filial"
                  >
                    <MenuItem value="">
                      <em>Nenhum</em>
                    </MenuItem>
                    {companies.map((item, index) => (
                      <MenuItem key={index} value={`${item.filial}`}>
                        {`${item.filial} - ${item.nome}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="date"
                  variant="outlined"
                  label="Data Início"
                  type="date"
                  fullWidth
                  disabled={filial === ""}
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="date"
                  variant="outlined"
                  label="Data Final"
                  type="date"
                  fullWidth
                  disabled={filial === ""}
                  value={finalDate}
                  onChange={(e) => setFinalDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleSearchFaults}
                  disabled={filial === ""}
                >
                  Buscar
                </Button>
              </Grid>
            </Grid>
          </Form>
        </div>
        <Backdrop className={classes.backdrop} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
      <br />
      <Container component="main" maxWidth="sm">
        {tableDate.length !== 0 && (
          <Card sx={{ border: "1px solid rgba(0,0,0, 0.2)" }}>
            <CardContent>
              <UserTable headers={headers} userData={tableDate} />
            </CardContent>
          </Card>
        )}
        <ToastContainer />
      </Container>
    </>
  );
}
