import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";

import store from "./store";
import Routes from "./routes";
import history from "./services/history";
import { createTheme, ThemeProvider } from "@mui/material";
import { red } from "@material-ui/core/colors";

const theme = createTheme({
  palette: {
    primary: { main: red[400] },
    secondary: { main: red[400] },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router history={history}>
          <Routes />
        </Router>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
