import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import ButtonNovo from '../../component/ButtonRed';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function InteragirAtendimento({ history }) {
  const classes = useStyles();

  function handleAtendimetoAberto(e) {
    e.preventDefault();
    history.push('RelatorioAtendimentoAbertos');
  }

  function handleAtendimetoFechado(e) {
    e.preventDefault();
    history.push('RelatorioAtendimentoFechados');
  }

  return (
    <>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <ButtonNovo
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleAtendimetoAberto}
          className={classes.submit}
        >
          Atedimentos Abertos
        </ButtonNovo>
        <ButtonNovo
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleAtendimetoFechado}
          className={classes.submit}
        >
          Atedimentos Encerrados
        </ButtonNovo>
      </Container>
    </>
  );
}
