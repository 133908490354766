import React, { useEffect, useState, memo } from "react";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import PersonIcon from "@material-ui/icons/Person";
import { red } from "@material-ui/core/colors";

import { uniqueId } from "lodash";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
    display: "flex",
    flexDirection: "column",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function Comentario({ id }) {
  const classes = useStyles();

  const [comentarios, setComentarios] = useState({});

  //const dataLocalStorage = JSON.parse(localStorage.getItem('login'));
  useEffect(() => {
    async function fetchData() {
      const response = await api.get(`atendimento/${id}/comentarioatendimento`);
      setComentarios(response.data);
    }
    fetchData();
  }, [id]);

  return !!comentarios.length === false
    ? ""
    : comentarios.map((comentario) => (
        <div key={uniqueId()}>
          <List className={classes.root}>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: red[600] }}>
                  {comentario.usuario.gestor === true ? (
                    <PersonIcon />
                  ) : (
                    <PermIdentityIcon />
                  )}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${comentario.usuario.nome}: ${comentario.mensagem}`}
                secondary={comentario.data}
              />
              {/** 
         <ListItemText primary={dataLocalStorage.id === comentario.usuarios_id ?  "Você: "+comentario.mensagem : "Agente: "+comentario.mensagem } secondary={comentario.data} />
        */}
            </ListItem>
            <Divider variant="inset" component="li" />
          </List>
        </div>
      ));
}
export default memo(Comentario);
