import React, { useEffect } from "react";
//import {useDispatch} from 'react-redux';
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { red } from "@material-ui/core/colors";

import ButtonNovo from "../../component/ButtonRed";

import history from "../../services/history";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const ButtonRed = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[600]),
    backgroundColor: red[600],
    "&:hover": {
      backgroundColor: red[900],
    },
  },
}))(Button);

export default function Dashboard(/*{ history } */) {
  const classes = useStyles();

  const dataLocalStorage = JSON.parse(localStorage.getItem("login"));

  useEffect(() => {
    history.push("/atendimento");
  }, []);

  function handleHistoryNewAtendimento(e) {
    e.preventDefault();
    history.push("/atendimento");
  }

  function handleHistoryMyAtendimentos(e) {
    e.preventDefault();
    history.push("/listaratendimentos");
  }

  function handleGestaoAtendimentos(e) {
    e.preventDefault();
    history.push("/gestaoatendimentos");
  }

  function handleGerirAtendimentos(e) {
    e.preventDefault();
    history.push("/agenteatendimentos");
  }

  function handleAtendimentosEquipe(e) {
    e.preventDefault();
    history.push("/acompanharequipe");
  }

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Dashboard
          </Typography>
          <form className={classes.form} noValidate>
            <>
              <ButtonNovo
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                onClick={handleHistoryNewAtendimento}
                className={classes.submit}
              >
                Novo Atendimento
              </ButtonNovo>
              <ButtonRed
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                onClick={handleHistoryMyAtendimentos}
                className={classes.submit}
              >
                Meus Atendimentos
              </ButtonRed>
            </>
            {dataLocalStorage.gestor === true ? (
              <>
                <ButtonRed
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={handleGestaoAtendimentos}
                  className={classes.submit}
                >
                  Gestão de Atendimentos
                </ButtonRed>
                <ButtonRed
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={handleAtendimentosEquipe}
                  className={classes.submit}
                >
                  Acompanhar demandas Equipe
                </ButtonRed>
              </>
            ) : (
              ""
            )}
            {dataLocalStorage.agente === true ? (
              <ButtonRed
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                onClick={handleGerirAtendimentos}
                className={classes.submit}
              >
                Atendimentos Atribuídos a mim
              </ButtonRed>
            ) : (
              ""
            )}
          </form>
        </div>
      </Container>
    </>
  );
}
