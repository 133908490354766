import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import packageJson from '../../../package.json';

const Copyright = () => (
    <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="http://redemarajo.com.br/">
        Rede Marajó 
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'} Versão: {packageJson.version}
    </Typography>
);

export default Copyright;