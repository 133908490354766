import axios from "axios";

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3080"
      : "https://api.painel-rh.rmti7.com",
});

export default api;
