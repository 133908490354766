/* eslint-disable no-unused-vars */
import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import FolderSharedIcon from "@material-ui/icons/FolderShared";
import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation";
import RateReviewIcon from "@material-ui/icons/RateReview";
import FolderSpecialIcon from "@material-ui/icons/FolderSpecial";
import HttpIcon from "@material-ui/icons/Http";
import ReceiptIcon from "@material-ui/icons/Receipt";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { Link } from "react-router-dom";

// const preventDefault = (event) => event.preventDefault();
const dataLocalStorage = JSON.parse(localStorage.getItem("login"));
const gestor = dataLocalStorage === null ? false : dataLocalStorage.gestor;

export const mainListItems = (
  <div>
    <Link
      to="/dashboard"
      color="inherit"
      style={{ color: "inherit", textDecoration: "none" }}
    >
      <ListItem button>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
    </Link>
    <Link
      to="/atendimento"
      color="inherit"
      style={{ color: "inherit", textDecoration: "none" }}
    >
      <ListItem button>
        <ListItemIcon>
          <AddCircleOutlineIcon />
        </ListItemIcon>
        <ListItemText primary="Novo Atendimento" />
      </ListItem>
    </Link>
    <Link
      to="/listaratendimentos"
      color="inherit"
      style={{ color: "inherit", textDecoration: "none" }}
    >
      <ListItem button>
        <ListItemIcon>
          <FolderSharedIcon />
        </ListItemIcon>
        <ListItemText primary="Meus Atendimentos" />
      </ListItem>
    </Link>

    {gestor && (
      <Link
        to="/registerUser"
        color="inherit"
        style={{ color: "inherit", textDecoration: "none" }}
      >
        <ListItem button>
          <ListItemIcon>
            <HowToRegIcon />
          </ListItemIcon>
          <ListItemText primary="Cadastrar Usuário" />
        </ListItem>
      </Link>
    )}

    {gestor && (
      <>
        <Link
          to="/gestaoatendimentos"
          color="inherit"
          style={{ color: "inherit", textDecoration: "none" }}
        >
          <ListItem button>
            <ListItemIcon>
              <RateReviewIcon />
            </ListItemIcon>
            <ListItemText primary="Gestão Atendimentos" />
          </ListItem>
        </Link>
        <Link
          to="/acompanharequipe"
          color="inherit"
          style={{ color: "inherit", textDecoration: "none" }}
        >
          <ListItem button>
            <ListItemIcon>
              <TransferWithinAStationIcon />
            </ListItemIcon>
            <ListItemText primary="Acompanhar Equipe" />
          </ListItem>
        </Link>
      </>
    )}
    <Link
      to="/agenteatendimentos"
      color="inherit"
      style={{ color: "inherit", textDecoration: "none" }}
    >
      <ListItem button>
        <ListItemIcon>
          <FolderSpecialIcon />
        </ListItemIcon>
        <ListItemText primary="Atribuídos a mim" />
      </ListItem>
    </Link>
    <Link
      to="/relatorios"
      color="inherit"
      style={{ color: "inherit", textDecoration: "none" }}
    >
      <ListItem button>
        <ListItemIcon>
          <ReceiptIcon />
        </ListItemIcon>
        <ListItemText primary="Relatórios" />
      </ListItem>
    </Link>
    {gestor && (
      <Link
        to="/openTicket"
        color="inherit"
        style={{ color: "inherit", textDecoration: "none" }}
      >
        <ListItem button>
          <ListItemIcon>
            <LockOpenIcon />
          </ListItemIcon>
          <ListItemText primary="Reabrir atendimento" />
        </ListItem>
      </Link>
    )}

    <Link
      to="/faltaCaixa"
      color="inherit"
      style={{ color: "inherit", textDecoration: "none" }}
    >
      <ListItem button>
        <ListItemIcon>
          <MonetizationOnIcon />
        </ListItemIcon>
        <ListItemText primary="Faltas de Caixa" />
      </ListItem>
    </Link>
  </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Links Úteis</ListSubheader>
    <a
      href="http://ajuda.redemarajo.com.br/"
      target="_blank"
      rel="noopener noreferrer"
      color="inherit"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <ListItem button>
        <ListItemIcon>
          <HttpIcon />
        </ListItemIcon>
        <ListItemText primary="Ajuda Rede Marajó" />
      </ListItem>
    </a>
    <a
      href="http://suporte.redemarajo.com.br/servicedesk/"
      target="_blank"
      rel="noopener noreferrer"
      color="inherit"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <ListItem button>
        <ListItemIcon>
          <HttpIcon />
        </ListItemIcon>
        <ListItemText primary="Nosso Suporte" />
      </ListItem>
    </a>
    <a
      href="http://protheus.redemarajo.com.br/"
      target="_blank"
      rel="noopener noreferrer"
      color="inherit"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <ListItem button>
        <ListItemIcon>
          <HttpIcon />
        </ListItemIcon>
        <ListItemText primary="Protheus Web" />
      </ListItem>
    </a>
  </div>
);
