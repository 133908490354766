import React, { useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import MaterialTable from "material-table";

import ButtonNovo from "../../../component/ButtonRed";
import api from "../../../services/api";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const dataLocalStorage = JSON.parse(localStorage.getItem("login"));
const filialUsuario =
  dataLocalStorage === null ? false : dataLocalStorage.filiais;

// console.log(filialUsuario.split("-"));

const gestor = dataLocalStorage === null ? false : dataLocalStorage.gestor;

export default function InteragirAtendimento({ history }) {
  const classes = useStyles();

  const tableHeader = {
    columns: [
      { title: "#ID", field: "id" },
      { title: "Filial", field: "filial" },
      {
        title: "Descrição",
        field: "descricao",
      },
      { title: "Solicitante", field: "user_id_solicitante.nome" },
      { title: "Data Abertura", field: "data" },
      { title: "Responsável", field: "user_id_responsavel.nome" },
      { title: "Descrição", field: "descricao" },
      { title: "Status", field: "status" },
    ],
  };

  const [tableData, setTableData] = React.useState([]);
  const dataLocalStorage = JSON.parse(localStorage.getItem("login"));
  const user_responsavel = dataLocalStorage.id;

  useEffect(() => {
    async function fetchData() {
      const response = await api.get(`/atendimentoAberto`);
      setTableData(response);
    }
    fetchData();
  }, [user_responsavel]);

  function handleHistoryBack(e) {
    e.preventDefault();
    history.push("/relatorios");
  }

  function handleAtendimentoId(e, id) {
    e.preventDefault();
    history.push(`/interagiratendimento/${id}`);
  }

  return (
    <>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <div className={classes.paper}>
          {/*<img src={logo} className="App-logo" alt="logo" height="100" />*/}
          <form className={classes.form} noValidate>
            <MaterialTable
              title="Atendimentos em aberto"
              isLoading={!tableData.data}
              columns={tableHeader.columns}
              data={tableData.data}
              actions={[
                {
                  icon: "search",
                  tooltip: "Visualizar",
                  onClick: (event, rowData) => {
                    // gestor === true
                    if (gestor === true) {
                      handleAtendimentoId(event, rowData.id);
                    }
                    // faz parte do grupo 01
                    else if (filialUsuario.includes("01-")) {
                      handleAtendimentoId(event, rowData.id);
                    }
                    // filial usuario === filial do item
                    else if (filialUsuario.includes(`${rowData?.filial}`)) {
                      handleAtendimentoId(event, rowData.id);
                    } else {
                      Swal.fire({
                        title: "Ops...",
                        text: "Você tentou visualizar o atendimento de outra unidade.",
                        icon: "warning",
                      });
                    }
                  },
                },
              ]}
              localization={{
                header: {
                  actions: "Visualizar",
                },
                toolbar: {
                  searchPlaceholder: "Pesquisar qualquer campo",
                },
                body: {
                  emptyDataSourceMessage: "Não há registros a serem exibidos",
                  editRow: {
                    deleteText: "Realmente Deseja Excluir?",
                  },
                },
                pagination: {
                  labelRowsSelect: "Linhas",
                  labelDisplayedRows: "{from}-{to} de {count}",
                  firstTooltip: "Primeira Página",
                  lastTooltip: "Última Página",
                  nextTooltip: "Próxima Página",
                  previousTooltip: "Página Anterior",
                },
              }}
              options={{
                // pageSize: 10,
                actionsColumnIndex: -1,
                pageSizeOptions: [5, 10, 20, 30],
                paginationType: "stepped",
              }}
            />
            <ButtonNovo
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleHistoryBack}
              className={classes.submit}
            >
              Voltar
            </ButtonNovo>
          </form>
        </div>
      </Container>
    </>
  );
}
