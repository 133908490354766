import React, { useEffect, useState } from "react";
import { Grid, TextField } from "@mui/material";
import api from "../../services/api";
import { format } from "date-fns";

export default function Atestados({ id }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await api.get(`atestados/${id}`);
      setData(response.data);
    }
    fetchData();
  }, [id]);

  let dataAplicação;
  let dataRetorno;
  const aplicacao = data[0]?.aplicacao;
  const retorno = data[0]?.retorno;

  if (aplicacao) {
    const date = new Date(aplicacao);

    if (!isNaN(date)) {
      // Verifica se a data é válida
      dataAplicação = format(date, "dd/MM/yyyy");
    } else {
      console.log("Data inválida:", aplicacao);
    }
  }

  if (retorno) {
    const date = new Date(retorno);

    if (!isNaN(date)) {
      // Verifica se a data é válida
      dataRetorno = format(date, "dd/MM/yyyy");
    } else {
      console.log("Data inválida:", retorno);
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          id="tipo_movimentacao"
          label="Tipo de Movimentação"
          fullWidth
          value={data[0]?.tipo || ""}
          disabled
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          id="nome"
          label="Nome"
          fullWidth
          value={`${data[0]?.matricula} -  ${data[0]?.nome}` || ""}
          disabled
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          id="funcao"
          label="Função"
          fullWidth
          value={data[0]?.funcao || ""}
          disabled
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          id="aplicacao"
          label="Aplicação"
          fullWidth
          value={dataAplicação || ""}
          disabled
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          id="retorno"
          label="Retorno"
          fullWidth
          value={dataRetorno || ""}
          disabled
        />
      </Grid>
    </Grid>
  );
}
