import React, { useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import MaterialTable from "material-table";
import Typography from "@material-ui/core/Typography";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { format, lastDayOfMonth } from "date-fns";
import pt from "date-fns/locale/pt-BR";
import Swal from "sweetalert2";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import ButtonNovo from "../../../component/ButtonRed";
import api from "../../../services/api";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const dataLocalStorage = JSON.parse(localStorage.getItem("login"));
const filialUsuario =
  dataLocalStorage === null ? false : dataLocalStorage.filiais;

const gestor = dataLocalStorage === null ? false : dataLocalStorage.gestor;

export default function InteragirAtendimento({ history }) {
  const classes = useStyles();

  const tableHeader = {
    columns: [
      { title: "#ID", field: "id" },
      { title: "Filial", field: "filial" },
      { title: "Solicitante", field: "user_id_solicitante.nome" },
      { title: "Data", field: "data" },
      { title: "Data Enc.", field: "data_encerramento" },
      { title: "Responsável", field: "user_id_responsavel.nome" },
      { title: "Descrição", field: "descricao" },
      { title: "Funcionario Demissão", field: "demissao[0].nome" },
      { title: "Funcionario Mud. Func.", field: "mudanca_funcao[0].nome" },
    ],
  };

  const [tableData, setTableData] = useState([]);
  const [selectedDate, handleDateChange] = useState(null);
  const [pesquisa, setPesquisa] = useState(false);
  // const dataLocalStorage = JSON.parse(localStorage.getItem('login'));
  // const user_responsavel = dataLocalStorage.id;

  /*useEffect(() => {
    async function fetchData() {
      const response = await api.get(`/atendimentoFinalizado`);
      setTableData(response);
    }
    if (!!selectedDate) {
      fetchData();
    }
  }, [selectedDate]);*/
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom",
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  function handleHistoryBack(e) {
    e.preventDefault();
    history.push("/relatorios");
  }

  async function handleAtendimentosFechados(e) {
    e.preventDefault();
    if (selectedDate === null) {
      Toast.fire({
        icon: "error",
        title: "Favor Selecionar Mês/Ano",
      });
      return;
    }
    Toast.fire({
      icon: "success",
      title: "Buscando dados...",
    });
    const dataRelatorio = format(lastDayOfMonth(selectedDate), "yyyy-MM-dd");
    const response = await api.get(`/atendimentoFinalizado/${dataRelatorio}`);
    setTableData(response);
    setPesquisa(true);
  }

  function handleAtendimentoId(e, id) {
    e.preventDefault();
    history.push(`/interagiratendimento/${id}`);
  }

  return (
    <>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography
            component="h1"
            variant="h5"
            style={{ marginTop: 30, marginBottom: 30 }}
          >
            Selecione o Ano/Mês
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
            <KeyboardDatePicker
              autoOk
              fullWidth
              // //margin="normal"
              variant="inline"
              openTo="year"
              views={["year", "month"]}
              color="secondary"
              inputVariant="outlined"
              label="Mês do Relatório"
              format="MM/yyyy"
              value={selectedDate}
              InputAdornmentProps={{ position: "start" }}
              onChange={handleDateChange}
            />
          </MuiPickersUtilsProvider>
          <form className={classes.form} noValidate>
            {pesquisa && (
              <MaterialTable
                title="Atendimentos Fechados"
                isLoading={!tableData.data}
                columns={tableHeader.columns}
                data={tableData.data}
                actions={[
                  {
                    icon: "search",
                    //icon: 'edit',
                    tooltip: "Visualizar",
                    onClick: (event, rowData) => {
                      // gestor === true
                      if (gestor === true) {
                        handleAtendimentoId(event, rowData.id);
                      }
                      // faz parte do grupo 01
                      else if (filialUsuario.includes("01-")) {
                        handleAtendimentoId(event, rowData.id);
                      }
                      // filial usuario === filial do item
                      else if (filialUsuario.includes(`${rowData?.filial}`)) {
                        handleAtendimentoId(event, rowData.id);
                      } else {
                        Swal.fire({
                          title: "Ops...",
                          text: "Você tentou visualizar o atendimento de outra unidade.",
                          icon: "warning",
                        });
                      }
                    },
                  },
                ]}
                localization={{
                  header: {
                    actions: "Visualizar",
                  },
                  toolbar: {
                    searchPlaceholder: "Pesquisar qualquer campo",
                  },
                  body: {
                    emptyDataSourceMessage: "Não há registros a serem exibidos",
                    editRow: {
                      deleteText: "Realmente Deseja Excluir?",
                    },
                  },
                  pagination: {
                    labelRowsSelect: "Linhas",
                    labelDisplayedRows: "{from}-{to} de {count}",
                    firstTooltip: "Primeira Página",
                    lastTooltip: "Última Página",
                    nextTooltip: "Próxima Página",
                    previousTooltip: "Página Anterior",
                  },
                }}
                options={{
                  // pageSize: 10,
                  actionsColumnIndex: -1,
                  pageSizeOptions: [5, 10, 20, 30],
                  paginationType: "stepped",
                }}
              />
            )}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <ButtonNovo
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="inherit"
                  onClick={handleAtendimentosFechados}
                  className={classes.submit}
                >
                  Pesquisar
                </ButtonNovo>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="inherit"
                  onClick={handleHistoryBack}
                  className={classes.submit}
                >
                  Voltar
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
