/* eslint-disable no-unused-vars */
import {
  Button,
  Container,
  CssBaseline,
  // FormControl,
  // TextField,
  Typography,
  Backdrop,
  CircularProgress,
  // InputLabel,
  // Select,
  MenuItem,
} from "@material-ui/core";
import ButtonNovo from "../../component/ButtonRed";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

import React, { useEffect, useState } from "react";
import api from "../../services/api";
import Swal from "sweetalert2";
import { Form } from "@unform/web";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
    display: "flex",
    flexDirection: "column",
  },
}));

export default function CadastrarUsuario() {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [userProtheus, setUserProtheus] = useState("");
  const [pass, setPass] = useState("");
  const [codProtheus, setCodProtheus] = useState("");
  const [load, setLoad] = useState(false);
  const [listFilial, setListFilial] = useState([]);
  const [filial, setFilial] = useState("");
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    async function filialList() {
      const response = await api.get(`/filiais`);

      setListFilial(response.data);
    }

    filialList();
  }, []);

  async function User() {
    try {
      const { data } = await api.post("/userProtheus", {
        usuario: userProtheus,
        filial,
      });

      setCodProtheus(data?.userCode);

      if (data?.userCode === "" && userProtheus !== "") {
        Swal.fire({
          icon: "info",
          title: `Usuário protheus não encontrado!`,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "info",
        title: `${error}`,
        showConfirmButton: false,
        timer: 3000,
      });
    }
  }

  async function RegisterUser() {
    setLoad(true);
    try {
      const response = await api.post(`users`, {
        cod_protheus: codProtheus,
        nome: name,
        email,
        senha: pass,
        gestor: false,
        agente: true,
        filial,
      });

      if (response.data) {
        setCodProtheus("");
        setName("");
        setEmail("");
        setLoad(false);
        setUserProtheus("");

        Swal.fire({
          icon: "success",
          title: `Usuário cadastrado com sucesso`,
          showConfirmButton: false,
          timer: 3000,
        });
      }
      setLoad(false);

    } catch (error) {
      setLoad(false);
      console.log(error);
    } finally {
      setLoad(false);
    }
  }

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5" style={{ marginTop: 30 }}>
            Cadastrar Usuário
          </Typography>
          <br />
          <Form className={classes.form} noValidate>
            <FormControl variant="outlined" fullWidth>
              <InputLabel
                ref={inputLabel}
                id="demo-simple-select-outlined-label"
                color="secondary"
                //margin="normal"
              >
                Selecione a Filial
              </InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={filial}
                onChange={(e) => setFilial(e.target.value)}
                labelWidth={labelWidth}
                color="secondary"
                margin="normal"
                name="filial"
                required
                label="Selecione a Filial"
              >
                <MenuItem value="">
                  <em>Nenhum</em>
                </MenuItem>
                {listFilial.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={`${item.GRUPO}-${item.COD_FILIAL}`}
                  >
                    {`${item.GRUPO} - ${item.COD_FILIAL} - ${item.NOME_FILIAL}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <TextField
                variant="outlined"
                type="text"
                label="Nome"
                color="secondary"
                fullWidth
                margin="normal"
                value={name}
                onChange={(e) => setName(e.target.value.trimStart())}
                required
                helperText="Campo obrigatório *"
              />

              <TextField
                variant="outlined"
                type="text"
                label="Email"
                color="secondary"
                margin="normal"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value.trim())}
                required
                helperText="Campo obrigatório *"
              />

              <TextField
                variant="outlined"
                type="text"
                label="Usuário Protheus"
                color="secondary"
                margin="normal"
                fullWidth
                onBlur={User}
                value={userProtheus}
                onChange={(e) => setUserProtheus(e.target.value.trim())}
                required
                helperText="Campo obrigatório *"
              />

              <ButtonNovo
                onClick={RegisterUser}
                variant="contained"
                fullWidth
                color="primary"
                disabled={
                  name === "" ||
                  email === "" ||
                  userProtheus === "" ||
                  codProtheus === "" ||
                  filial === ""
                }
              >
                Cadastrar
              </ButtonNovo>

              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={load}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </FormControl>
          </Form>
        </div>
      </Container>
    </>
  );
}
